import React from "react";

import CdCards from "./cd-cards";

const SavingsOverviewCDsSpanish = () => {
  return (
    <section id="savings-overview-section-cds-es" className="container">
      <div className="row">
        <CdCards />
      </div>
      <p className="text-sm mb-2">
        WaFd Bank no pide su reporte crediticio para esta aplicación.<sup>1</sup>
      </p>
      <p className="text-muted text-sm mb-2">
        <sup>1</sup>Para abrir una cuenta, WaFd Bank no pide su reporte crediticio de ninguna de las tres agencias de
        crédito. Revisaremos OFAC y ChexSystems<sup>&reg;</sup>.
      </p>
      <p className="text-muted text-sm mb-0">
        <sup>&dagger;</sup>Las cuentas de cheques elegibles incluyen Free, Rewards, Premium Rewards o Interest Checking.
      </p>
    </section>
  );
};
export default SavingsOverviewCDsSpanish;
