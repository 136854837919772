import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import Icon from "../custom-widgets/icon";
import TwoColumnSectionWithImage from "../custom-widgets/two-column-section-with-image";

import { useLanguageContext } from "../../contexts/language-context";

const CoverdellEducationSavings = () => {
  const isSpanish = useLanguageContext();

  const SpanishContent = () => {
    return (
      <>
        <h2 className="text-success font-weight-semi-bold">Ahorros para educación de Coverdell</h2>
        <p className="lead">Ahorre hoy para el futuro de su hijo.</p>
        <p>
          Con una cuenta de ahorros para educación Coverdell, puede contribuir hasta $ 2000 después de impuestos al año
          por niño. Y la mejor parte es que cuando se retiran los fondos, están libres de impuestos.
        </p>
        <p>
          Una Coverdell ESA puede ayudarlo a ahorrar para los gastos de educación de un niño, como matrícula, cuotas,
          libros, suministros, equipo y, en algunos casos, alojamiento y comida. Las contribuciones pueden ser
          realizadas por miembros de la familia, corporaciones u otras personas hasta que el niño cumpla 18 años. Los
          activos de la ESA también pueden transferirse o transferirse de una ESA a otra ESA para el mismo niño o un
          miembro de la familia elegible del niño.
        </p>
        <p className="font-weight-bold text-success">Esto es inteligente.</p>
        <Link id="cta-apply-at-branch-coverdell" to="/es/sucursales" className="btn btn-primary w-100 w-sm-auto">
          Abrir en sucursal
        </Link>
      </>
    );
  };
  const EnglishContent = () => {
    return (
      <>
        <h3 className="font-weight-semi-bold text-success mb-4">
          Save for your child's future today with a Coverdell Education Savings Account
        </h3>
        <p className="mb-3">
          With a Coverdell Education Savings Account you can contribute up to $2,000 after-tax annually per child. And
          the best part is, when the funds are withdrawn they're tax-free.
        </p>
        <p className="mb-4">
          A Coverdell ESA can help you save for a child's education expenses, such as tuition, fees, books, supplies,
          equipment, and in some cases, room and board. Contributions can be made by family members, corporations, or
          other individuals until the child reaches age 18. Assets from the ESA can also be transferred or rolled over
          from one ESA to another ESA for the same child or an eligible family member of the child.
        </p>
        <p className="text-success font-weight-bold mb-0">
          Now that's smart.{" "}
          <Link id="cta-apply-at-branch-coverdell" to="/locations" className="text-decoration-none">
            Apply at a branch near you
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
      </>
    );
  };

  return (
    <TwoColumnSectionWithImage id="coverdell-section" contentOnLeft={false}>
      {{
        image: (
          <StaticImage
            quality="100"
            placeholder="blurred"
            className="border-radius-12"
            src="../../images/thumbnail-coverdell-education-730.jpg"
            alt="Mother and son hugging as she drops him off at college."
          />
        ),
        content: isSpanish ? <SpanishContent /> : <EnglishContent />
      }}
    </TwoColumnSectionWithImage>
  );
};
export default CoverdellEducationSavings;
