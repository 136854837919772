import React from "react";

import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../../layouts/secondary-landing";
import HeroChevron from "../../../../components/hero/hero-chevron";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SavingsOverview from "../../../../components/savings-overview/savings-overview";
import SavingsOverviewCDsSpanish from "../../../../components/savings-overview/savings-overview-cds-spanish";
import CoverdellEducationSavings from "../../../../components/coverdell-education-savings/coverdell-education-savings";
import ContactInfo from "../../../../components/contact-info/contact-info";
import SEO from "../../../../components/seo/seo";
import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";
import MiniBandSection from "../../../../components/savings-account/mini-band-section";

const SavingsAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/savings-account/hero-savings-money-market-12272022-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "savings-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Una familia jugando en la cocina mientras preparan alimentos.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Ahorrando tiempo y dando tranquilidad"
          }
        },
        {
          id: 2,
          button: {
            id: "savings-account-hero-instant-open-button",
            class: "btn-white",
            text: "Abrir una cuenta",
            url: "#savings-overview-section"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      active: true,
      title: "Cuentas de ahorro, CD y cuentas Money Market"
    }
  ];
  const title = "Cuentas de ahorro",
    description =
      "Elija la cuenta de ahorro que se adapte a sus necesidades. En WaFd Bank puede abrir cuentas de ahorro, certificados de depósito y cuentas de mercado monetario.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/cuentas-de-ahorro"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-savings-money-market-12272022.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Cuentas de ahorro, CD y cuentas Money Market</h1>
        <h2 className="text-green-60 mb-0">
          Tenemos las herramientas y el servicio para ayudarlo a crecer su dinero con confianza.
        </h2>
      </section>
      <MiniBandSection className="py-0" />
      {/* To do: Below component shows different ways of handling spanish components. Figure out best possible way */}
      <SavingsOverview isSpanish={true} />
      <SavingsOverviewCDsSpanish />
      <CoverdellEducationSavings />
      <ContactInfo isSpanish={true} />
    </SecondaryLanding>
  );
};

export default SavingsAccount;
